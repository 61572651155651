import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import { searchUsers } from "../../../graphql/queries";
import { UserContext } from '../../../contexts/userContext';
import { useContext } from "react";
import { isOwner } from '../../../utils';
import { updateUser } from "../../../graphql/mutations";
import Swal from 'sweetalert';
import { toast } from "react-toastify";
import awsmobile from "../../../aws-exports";
import { getAllUser } from "../../../excelHealper/users";
import DatePicker from "react-datepicker";
import moment from "moment";


const UserList = () => {

    const [spinner, showSpinner] = useState(false);
    const [items, setItems] = useState()
    const { user } = useContext(UserContext);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState();
    const [sortItem, setSortItem] = useState({ field: 'updatedAt', direction: 'desc' })
    const [filteredRole, setFilteredRole] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023/04/01"));
    const [endDate, setEndDate] = useState(new Date("2023/04/01"));
    const [downloadStatus, setDownloadStatus] = useState(false)
    const limit = 100;

    const roleArray = [{ name: 'Professor', value: 'TEACHER' }, { name: 'Student', value: 'USER' }, { name: 'Admin', value: 'ADMIN' }]


    const getList = async (from) => {
        try {
            showSpinner(true)
            let filter = { and: { role: { ne: 'OWNER' }, deleted: { eq: false } } }
            if (filteredRole) {
                filter = {
                    and: { role: { eq: filteredRole }, deleted: { eq: false } }
                }
            }
            if (search) {
                if (filteredRole) {
                    filter = { and: { role: { eq: filteredRole }, deleted: { eq: false }, or: [{ firstName: { matchPhrasePrefix: search } }, { lastName: { matchPhrasePrefix: search } }, { email: { matchPhrasePrefix: search } }] } }
                } else {
                    filter = { and: { role: { ne: 'OWNER' }, deleted: { eq: false }, or: [{ firstName: { matchPhrasePrefix: search } }, { lastName: { matchPhrasePrefix: search } }, { email: { matchPhrasePrefix: search } }, { phone: { matchPhrasePrefix: `+91${search}` } }] } }
                }
            }

            const response = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: filter,
                    limit,
                    sort: sortItem,
                    from
                }

            })
            setTotalCount(response?.data?.searchUsers?.total)

            let pages = [];
            for (let i = 1; i <= Math.ceil(response?.data?.searchUsers?.total / limit); i++) {
                pages.push(i)
            }
            setTotalPages(pages)
            setItems(response?.data?.searchUsers?.items)



            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteUser = async (id) => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                API.graphql({
                    query: updateUser,
                    variables: {
                        input: {
                            id: id,
                            deleted: true
                        }
                    }
                }).then((res) => {
                    deleteCognitoUser(id)
                }).catch((err) => toast.error(err.message))
            }
        })
    }



    const deleteCognitoUser = async (id) => {
        try {
            await fetch(`${awsmobile?.aws_cloud_logic_custom[0]?.endpoint}/auth/user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            toast.success('User deleted successfully')
            getList(null, true)
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }


    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getList((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getList(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const hanldeSort = (fieldName) => {
        setSortItem((prev) => {
            if (prev?.field === fieldName) {
                if (prev.direction === 'asc') {
                    return ({ field: fieldName, direction: 'desc' })
                } else {
                    return ({ field: fieldName, direction: 'asc' })
                }
            } else {
                return ({ field: fieldName, direction: 'desc' })
            }
        })
    }
    const handleDownload = async (event) => {
        event.preventDefault()
        setDownloadStatus(true)
        await getAllUser(startDate, endDate)
        setDownloadStatus(false)
    }

    useEffect(() => {
        getList(0)
    }, [search, sortItem, filteredRole])

    return (
        <div>
            <Header title='Youth Venture' name="Users" count={totalCount}>
                {isOwner(user?.role) &&
                    <Link to='/youth-venture/user/create' className='btn btn-primary'>Invite Admin</Link>}
            </Header>


            <div className='container-fluid pb-3'>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <form>
                                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                        <input className="form-control list-search" type="search" placeholder="Search" defaultValue={search} onChange={(e) => { setSearch(e?.target?.value) }} />
                                        <span className="input-group-text">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>

                                </form>
                            </div>
                            <div className='col-auto px-1'>
                                <div className='dropdown '>
                                    <span>Filter by Role : </span>
                                    {/* <select className="btn  btn-sm  border dropdown-menu-start" onChange={(e) => setFilteredRole(e.target.value)}>
                                        <option className="dropdown-item " value={''}>{'All'}</option>
                                        {roleArray?.map((role) => (
                                            <option className="dropdown-item " value={role.value} key={role.value}>{role?.name}</option>
                                        ))}
                                    </select> */}

                                    <select onChange={(e) => setFilteredRole(e.target.value)} className="dropdown-toggle border filter-dropdown" >
                                        <option className="dropdown-item" value={''}>{'All'}</option>

                                        {roleArray?.map((role) => (
                                            <option value={role.value} key={role.value}>{role?.name}</option>

                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="col-auto">
                                {/* <button className="btn btn-sm btn-white" onClick={handleDownload}>
                                    Download
                                    <i className="fe fe-download m-2"></i>
                                </button> */}

                                <div className="dropdown">
                                    <button className="btn btn-sm btn-white " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" >
                                        Download <i className="fe fe-download m-2"></i>
                                    </button>
                                    <form className="dropdown-menu dropdown-menu-end dropdown-menu-card " data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: " translate(0px, 32px)" }}>
                                        <div className="card-header">
                                            <h4 className="card-header-title">
                                                Download Excel file
                                            </h4>
                                        </div>
                                        <div className="card-body" style={{ overflowY: 'initial' }}>
                                            <div className="list-group list-group-flush mt-n4 mb-4">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>Start Date</small>

                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                endDate={endDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>End Date</small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => { setEndDate(new Date(date?.setHours(23, 59, 0, 0))) }}
                                                                selectsEnd
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                minDate={startDate}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn w-100 btn-primary" disabled={downloadStatus} onClick={handleDownload}>
                                                {downloadStatus ? "Downloading..." : "Download"}
                                            </button>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Spinner display={spinner}>
                        <div className="table-responsive">
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('firstName') }} data-sort="item-name" >Name</span></th>
                                        <th> <span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('userCityId') }} data-sort="item-name" >City</span></th>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('phone') }} data-sort="item-name" >Phone</span></th>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('email') }} data-sort="item-name" >Email</span></th>
                                        <th className='text-center'>Registered On</th>
                                        <th colSpan={2}>Role</th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base ">
                                    {
                                        items && items?.map((item) => {
                                            return (
                                                <tr key={item?.id} >
                                                    <td>
                                                        {item?.firstName} {item?.lastName}
                                                    </td>
                                                    <td>
                                                        {item?.city?.name}
                                                    </td>

                                                    <td>
                                                        {item?.phone}
                                                    </td>

                                                    <td>
                                                        {item?.email}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            item?.createdAt ? <>
                                                                {moment(item?.createdAt).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(item?.createdAt).format('h:mm a')}</div>
                                                            </> : "No data"
                                                        }
                                                    </td>
                                                    <td>
                                                        {item?.role === 'USER' ? 'STUDENT' : item?.role}
                                                    </td>
                                                    {isOwner(user?.role) &&
                                                        <td className='text-end text-muted' onClick={(event) => { event.stopPropagation() }}>
                                                            <div className='dropdown'>
                                                                <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                                    <i className='fe fe-more-vertical'></i>
                                                                </div>
                                                                <div className='dropdown-menu dropdown-menu-end'>
                                                                    <div className='dropdown-item cursor-pointer' onClick={() => { handleDeleteUser(item?.id) }}>
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item cursor-pointer">
                                    {currentPage > 1 ?
                                        <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                            <i className="fe fe-arrow-left me-1"></i> Prev
                                        </button> : ""}

                                </li>
                            </ul>
                            <ul className="list-pagination pagination pagination-tabs card-pagination ">

                                {totalPages.map((page) => (
                                    <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getList((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                        <span className='page'>{page}</span>
                                    </li>))}
                            </ul>
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                                <li className="page-item cursor-pointer">
                                    {currentPage < totalPages[totalPages.length - 1] ?
                                        <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                            Next <i className="fe fe-arrow-right ms-1"></i>
                                        </button>
                                        : ""}
                                </li>
                            </ul>
                        </div>
                    </Spinner>
                </div>
            </div>
        </div >
    )
}

export default UserList;